import React, { useState, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import classes from './ClientUploads.module.css';
import {
    Row,
    Col,
    Button,
    Table,
    Progress,
    Space,
    Descriptions,
    Select,
    Input,
    InputNumber,
    AutoComplete,
    Modal,
    message,
    Tag,
    DatePicker,
    Form,
    Radio,
    Badge,
    Tooltip,
    Avatar
} from 'antd';
import {
    WarningTwoTone,
    ExclamationCircleOutlined,
    SearchOutlined,
    InfoCircleOutlined,
    FilterOutlined,
    UndoOutlined,
    ExportOutlined
} from '@ant-design/icons';
import {
    fetchAllScanData,
    deleteMap,
    fetchClientCodes,
    updateMapInfo,
    createRecord,
    saveClientData,
    deleteClient,
    getExpectedSubstances,
    searchMassSpecData,
    saveMassSpecRow,
    fetchOperators
} from '../utils/dbHelpers.js';
import {
    scanningState,
} from '../stateManagement/processState';
import {
    allScanDataState,
    deviceInfoState,
    userNameState,
    dateRangeState,
    clientCodesState,
    isSubstanceInfoModalOpenState,
    infoModalSubstanceState,
    allSubstancesDataState,
    defaultDateRange,
    isImageModalOpenState,
    isNewRecordCreatedState,
    imageFilenameState,
    imageInfoSerialState,
    expandImageInfoSerialRowTrigger,
    isTestInfoModalOpenState,
    massSpectrometryTableDataState,
    isAdminState,
    riskAwarenessShouldUpdateState,
    operatorsState,
    operatorsMapState,
    currentOperatorState,
    expectedSubstancesState
} from '../stateManagement/commonState';
import { getFormattedWeekString, getWeek, isUUID } from '../utils/helpers';
import dayjs from 'dayjs';
import ISO6391 from 'iso-639-1';
import { cloneDeep, throttle } from 'lodash';
import ImageModal from './ImageModal';

const languages = ISO6391.getLanguages(ISO6391.getAllCodes()).map(e => ({ value: e.code, label: e.name }));

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;
const { RangePicker } = DatePicker;

const key = 'updatable';

let searchInput = '';

function ClientUploads({ mapViewButtonHandler, setCurrentSerial, setCurrentMapID, resultsTableData }) {
    const [allScanData, setAllScanData] = useAtom(allScanDataState);
    const [allSubstancesData] = useAtom(allSubstancesDataState);
    const [scanning] = useAtom(scanningState);
    const [deviceInfo] = useAtom(deviceInfoState);
    const [username] = useAtom(userNameState);
    const [disabledOfflineActions, setDisabledOfflineActions] = useState(!navigator.onLine);
    const [dataLoading, setDataLoading] = useState(false);
    const participantProfileFormRef = useRef(null);
    const [, setIsSubstanceInfoModalOpen] = useAtom(isSubstanceInfoModalOpenState);
    const [, setInfoModalSubstance] = useAtom(infoModalSubstanceState);
    const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
    const [scanDetectedFilterMethod, setScanDetectedFilterMethod] = useState('AND');
    const [selectedSubstancesForResultFilter, setSelectedSubstancesForResultFilter] = useState([]);
    const [resultsFilterIsIncusive, setResultsFilterIsIncusive] = useState(true);
    const [detectedSubstances, setDetectedSubstances] = useState([]);
    const [, setIsImageModalOpen] = useAtom(isImageModalOpenState);
    const [, setIsNewRecordCreated] = useAtom(isNewRecordCreatedState);
    const [, setImageFilename] = useAtom(imageFilenameState);
    const [expectedSubstances, setExpectedSubstances] = useAtom(expectedSubstancesState);
    const [imageInfoSerial, setImageInfoSerial] = useAtom(imageInfoSerialState);
    const [expandImageInfoSerialRow] = useAtom(expandImageInfoSerialRowTrigger);
    const [, setIsTestInfoModalOpen] = useAtom(isTestInfoModalOpenState);
    const [labSampleIDMatches, setLabSampleIDMatches] = useState([]);
    const [massSpectrometryTableData, setMassSpectrometryTableData] = useAtom(massSpectrometryTableDataState);
    const [isAdmin] = useAtom(isAdminState);
    const [recordLabSampleData, setRecordLabSampleData] = useState(null);
    const [, setRiskAwarenessShouldUpdate] = useAtom(riskAwarenessShouldUpdateState);
    const [operators, setOperators] = useAtom(operatorsState);
    const [operatorsMap, setOperatorsMap] = useAtom(operatorsMapState);
    const [currentOperator, setCurrentOperator] = useAtom(currentOperatorState);

    useEffect(() => {
        if (imageInfoSerial) {
            setFilteredInfo({ serial: [`${imageInfoSerial}`] });
            if (expandedRows[0]) { autoSaveRecord(expandedRows[0]) };
            setExpandedRows([imageInfoSerial]);
        }
    }, [expandImageInfoSerialRow]);

    const confirmRecordDelete = (record) => {
        confirm({
            width: 600,
            title: 'Do you want to delete this record?',
            icon: <ExclamationCircleOutlined />,
            content: (
                <div>
                    <br />
                    <table style={{ fontWeight: 'normal' }}>
                        <tbody>
                            <tr><td style={{ padding: '0 10px' }}>{'Serial No.'}</td><td>{record.serial}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Client Code'}</td><td>{record.client_code ? record.client_code : 'N/A'}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Date'}</td><td>{dayjs(record.created_at).format('MMM D, YYYY')}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Expected Substances'}</td><td>{record.expected_substances?.join(', ')}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Description'}</td><td>{record.substance_description}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Results'}</td><td>{record.label_names?.filter(e => e !== 'Aluminium').join(', ')}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Scan Impact'}</td><td>{record.impact}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Strength'}</td><td>{record.substance_strength}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Notes'}</td><td>{record.notes}</td></tr>
                        </tbody>
                    </table>
                </div>
            ),
            onOk() {
                setExpandedRows([]);
                deleteMap(record).then(async () => {
                    if (!navigator.onLine) {
                        let updatedMapData = allScanData.filter(item => item.scanTimestamp !== record.scanTimestamp);
                        setAllScanData(updatedMapData);
                    } else {
                        try {
                            let response = await deleteClient(username, record.client_code);
                            if (response.clientCode) {
                                message.info(`Client deleted: ${response.clientCode}`, 6);
                            }
                        } catch (error) { console.error(error); }
                    }
                    updateMapTable();
                }).catch(error => console.error('Map delete error! ', error));
            }
        });
    }

    const [expandedRows, setExpandedRows] = useState([]);
    const [clientCodes, setClientCodes] = useAtom(clientCodesState);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [dateRange, setDateRange] = useAtom(dateRangeState);
    const currentSerialNumber = React.useRef(null);

    useEffect(() => {
        if (scanning) {
            setDateRange(defaultDateRange);
            handleStartScan();
            setFilteredInfo({});
            setSelectedSubstancesForResultFilter([]);
            setResultsFilterIsIncusive(true);
            setScanDetectedFilterMethod('AND');
            setShowAdditionalFilters(false);
        }
    }, [scanning]);

    const handleStartScan = async () => {
        try {
            if (expandedRows[0]) { autoSaveRecord(expandedRows[0]) };
            const { serial, map_id } = await createRecord({
                username,
                week: getWeek(),
                device_id: deviceInfo.device_id,
                operator_id: currentOperator
            }, message, key);
            currentSerialNumber.current = serial;
            const imageFilename = (deviceInfo.device_camera_status && deviceInfo.device_network_status) ? `${serial}_${new Date().toISOString().replace(/[:T\-]/g, '').slice(0, -5)}_${username}_${deviceInfo?.device_firmware_version}.png` : null;
            setImageFilename(imageFilename);
            setCurrentSerial(serial);
            setIsNewRecordCreated(true);
            setCurrentMapID(map_id);
            let newRow = {
                "key": serial,
                "serial": serial,
                "map_id": map_id,
                "week": getWeek(),
                "client_code": null,
                "expected_substances": null,
                "substance_strength": null,
                "substance_description": null,
                "impact": null,
                "notes": "",
                "label_names": [],
                "label_count": [],
                "device_id": deviceInfo.device_id,
                "results": null,
                "gender": null,
                "age": null,
                "chronic_pain": null,
                "indigenous": null,
                "brpoc": null,
                "lgbtq": null,
                "preferred_language": null,
                "username": username,
                "image_filename": imageFilename,
                "operator": null,
                "operator_id": currentOperator,
                "testing_for": null,
                "consumed": null,
                "date": dayjs().format('MMM D, YYYY')
            }
            if (imageFilename) { await updateMapInfo(newRow); }
            let newAllScanData = cloneDeep(allScanData);
            setAllScanData([newRow, ...newAllScanData]);
            setCurrentOperator(null);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchExpectedSubstances();
    }, []);

    useEffect(() => {
        setDataLoading(false);
        const substanceFrequencyMap = {}
        for (let i = 0; i < allScanData.length; i++) {
            if (!allScanData[i].results) continue;
            Object.keys(allScanData[i].results).forEach(substance => {
                if (substanceFrequencyMap[substance]) {
                    substanceFrequencyMap[substance]++;
                } else {
                    substanceFrequencyMap[substance] = 1;
                }
            });
        }
        const sortedSubstanceFrequencyMap = Object.entries(substanceFrequencyMap).sort((a, b) => b[1] - a[1]);
        setDetectedSubstances(sortedSubstanceFrequencyMap);
        if (scanning) {
            setExpandedRows([currentSerialNumber.current]);
        } else {
            currentSerialNumber.current = null;
        }
        if (expandedRows.length) {
            fetchRecordLabSampleData(allScanData.find(s => s.serial === expandedRows[0])?.lab_sample_id);
        }
    }, [allScanData]);

    window.addEventListener('online', () => {
        setDisabledOfflineActions(false);
    });

    window.addEventListener('offline', () => {
        setDisabledOfflineActions(true);
    });

    const fetchExpectedSubstances = async () => {
        const expectedSubstances = await getExpectedSubstances();
        setExpectedSubstances(expectedSubstances);
    }

    const updateMapTable = async () => {
        if (username && dateRange) {
            if (navigator.onLine) {
                setDataLoading(true);
                try {
                    let scanData = (await fetchAllScanData({ username, dateRange })).map((map, index) => {
                        if (Boolean(Date.parse(map.week))) {
                            map.week = getFormattedWeekString(map.week);
                        }
                        if (!map.operator_id) { map.operator_id = map.operator; } // For backwards compatibility. Phase out eventually.
                        return { key: map.serial, ...map, date: dayjs(map.created_at).format('MMM D, YYYY') }
                    });
                    setAllScanData(scanData);
                    setRiskAwarenessShouldUpdate(true);
                } catch (error) {
                    setAllScanData([]);
                }
            }
        }
    }

    useEffect(() => {
        if (username) {
            updateClientCodes();
            updateMapTable();
            loadOperators();
        }
    }, [username]);

    useEffect(() => {
        setExpandedRows([]);
        updateMapTable();
    }, [dateRange]);

    const loadOperators = async () => {
        const res = await fetchOperators(username);
        if (res.success) {
            setOperators(res.data);
            setOperatorsMap((res.data || []).reduce((acc, operator) => {
                acc[operator.operator_id] = operator;
                return acc;
            }, {}));
        }
    };

    const updateClientCodes = async () => {
        fetchClientCodes(username).then((clientCodes) => {
            setClientCodes(clientCodes);
        });
    }

    const handleRecordChange = (row) => {
        const newData = cloneDeep(allScanData);
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setAllScanData(newData);
    };

    const getColumnSearchProps = (dataIndex, alias) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${alias}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => { handleReset(clearFilters) }} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
    });

    const clientUploadColumns = [
        ...(username === 'admin'
            ? [
                {
                    title: 'Username',
                    dataIndex: 'username',
                    key: 'username',
                    width: 125,
                    align: 'center',
                    render: text => (text ? (<div style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>{text}</div>) : null),
                    ...getColumnSearchProps('username', 'username'),
                    filteredValue: filteredInfo.username || null,
                },
            ]
            : []),
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            width: 120,
            align: 'center',
            ...getColumnSearchProps('serial', 'serial'),
            filteredValue: filteredInfo.serial || null,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 140,
            align: 'center'
        },
        {
            title: 'Expected Substances',
            dataIndex: 'expected_substances',
            key: 'expected_substances',
            width: 150,
            align: 'center',
            filters: [...expectedSubstances.sort().map(e => ({ text: e, value: e })), { text: 'Other', value: 'Other' }],
            onFilter: (value, record) => record.expected_substances?.includes(value),
            filteredValue: filteredInfo.expected_substances || null,
            render: substances => (
                substances?.map((e, i) => <Tag key={i} color="blue">{e}</Tag>)
            )
        },
        {
            title: 'Description',
            dataIndex: 'substance_description',
            key: 'substance_description',
            width: 250,
            align: 'center',
            ...getColumnSearchProps('substance_description', 'descriptions'),
            filteredValue: filteredInfo.substance_description || null,
            // render: text => (text ? `${text.length > 30 ? `${text.slice(0, 30)}...` : text}` : null)
            render: text => (
                text ? (
                    <Tooltip title={text}>
                        <span style={{ cursor: 'default' }}>{text.length > 30 ? `${text.slice(0, 30)}...` : text}</span>
                    </Tooltip>
                ) : null
            ),
        },
        {
            title: 'Strength',
            dataIndex: 'substance_strength',
            key: 'substance_strength',
            width: 130,
            align: 'center',
            filters: [
                {
                    text: 'Weak',
                    value: 'Weak',
                },
                {
                    text: 'Normal',
                    value: 'Normal',
                },
                {
                    text: 'Strong',
                    value: 'Strong',
                },
                {
                    text: 'Very Strong',
                    value: 'Very Strong',
                },
                {
                    text: 'Overdose',
                    value: 'Overdose',
                },
            ],
            onFilter: (value, record) => record.substance_strength === value,
            filteredValue: filteredInfo.substance_strength || null,
            render: text => {
                if (text) {
                    if (text.includes('Very Strong')) {
                        return <><WarningTwoTone twoToneColor="#faad14" /> {'Very Strong'}</>
                    } else if (text.includes('Overdose')) {
                        return <><WarningTwoTone twoToneColor="#f5222d" /> {'Overdose'}</>
                    } else {
                        return text
                    }
                }
            }
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            width: 250,
            align: 'center',
            ...getColumnSearchProps('notes', 'notes'),
            filteredValue: filteredInfo.notes || null,
            render: text => (text ? `${text.length > 30 ? `${text.slice(0, 30)}...` : text}` : null)
        },
        {
            title: `Client Code`,
            dataIndex: 'client_code',
            key: 'client_code',
            width: 180,
            align: 'center',
            ...getColumnSearchProps('client_code', 'client code'),
            filteredValue: filteredInfo.client_code || null,
            render: c => (!isUUID(c) ? c : null)
        },
        {
            title: 'Operator',
            dataIndex: 'operator_id',
            key: 'operator_id',
            width: 100,
            align: 'center',
            // ...getColumnSearchProps('operator_id', 'operator_id'),
            filteredValue: filteredInfo.operator || null,
            render: operator_id => {
                if (isUUID(operator_id) && operatorsMap?.[operator_id]) {
                    const { first_name, last_name, color } = operatorsMap[operator_id];
                    return (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', cursor: 'default' }}>
                            <Tooltip title={`${first_name} ${last_name}`}>
                                <Avatar size="small" shape="square" style={{ backgroundColor: color, color: '#fff', display: 'flex', alignItems: 'center', fontSize: '10px' }}>
                                    {first_name[0]}{last_name[0]}
                                </Avatar>
                            </Tooltip>
                        </div>
                    )
                } else { return `${((e = (operator_id || '')) => e.length > 10 ? `${e.slice(0, 10)}...` : e)()}` }
            }
        },
        {
            title: 'Action',
            key: 'map_action',
            width: 80,
            align: 'center',
            fixed: 'right',
            render: (record) => (
                <Space size="middle">
                    <Button
                        disabled={disabledOfflineActions || scanning || !record.device_id || !record.results || Object.keys(record.results).length === 0}
                        type="link"
                        size="small"
                        className={classes.actionButton}
                        onClick={e => {
                            e.stopPropagation();
                            message.loading({ content: 'Loading...', key, duration: 0 });
                            mapViewButtonHandler({ map_id: record.map_id, serial: record.serial, device_id: record.device_id });
                            setImageInfoSerial(record.serial);
                        }}
                    >
                        View
                    </Button>
                </Space>
            ),
        },
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    const expandIcon = () => {
        return null;
    };

    const rangePickerChangeHandler = (_, range) => {
        setDateRange({ from: range[0], to: range[1] });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };

    const saveScanMetadata = async (record) => {
        return new Promise(async (resolve, reject) => {
            try {
                message.loading({ content: 'Saving...', key, duration: 0 });
                if (!isUUID(record.operator_id)) { record.operator_id = null; }
                await updateMapInfo(record, message);
                message.success({ content: 'Scan info saved.', key, duration: 3 });
                resolve();
            } catch (error) {
                message.error({ content: 'Failed to save scan info.', key, duration: 6 });
                reject(error);
            }
        });
    }

    const updateRecordHandler = async (record) => {
        try {
            await saveScanMetadata(record);
            const relatedLabSample = massSpectrometryTableData.find(s => s.id === record.lab_sample_id);
            if (relatedLabSample && relatedLabSample.status === 'Pending Transit') {
                try {
                    await saveMassSpecRow({ ...relatedLabSample, status: 'In Progress' });
                    message.success(`Lab sample ${relatedLabSample.id.slice(0, 8).toUpperCase()} changed to "In Progress".`);
                    const index = massSpectrometryTableData.findIndex(test => test.id === relatedLabSample.id);
                    if (index !== -1) {
                        const updatedLabTestData = massSpectrometryTableData.map((item, idx) =>
                            idx === index ? { ...item, status: 'In Progress' } : item
                        );
                        setMassSpectrometryTableData(updatedLabTestData);
                    }
                } catch {
                    message.error(`Failed to update ${record.id.slice(0, 8).toUpperCase()}.`);
                }
            }
            record.newClientCode = false;
            record.newExpectedSubstances = false;
            record.newSubstanceStrength = false;
            record.newDescription = false;
            record.newImpact = false;
            record.newNotes = false;
            record.newOperator = false;
            record.newlabSampleID = false;
            let newAllScanData = cloneDeep(allScanData);
            let scanIndex = newAllScanData.findIndex(e => e.map_id == record.map_id);

            Object.keys(newAllScanData[scanIndex]).forEach((key) => {
                if (record.hasOwnProperty(key)) {
                    newAllScanData[scanIndex][key] = record[key];
                }
            });
            if (!isUUID(record.client_code) && !clientCodes.some(client => client.client_code === record.client_code)) {
                await fetchClientCodes(username).then((clientCodes) => {
                    setClientCodes(clientCodes);
                });
            }
            setAllScanData(newAllScanData);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        let scanIndex = allScanData.findIndex(e => e.key == currentSerialNumber.current);
        if (scanIndex !== -1) {
            let resultsJSON = resultsTableData.reduce(function (acc, cur) {
                acc[cur.substance] = cur.percentComposition / 100;
                return acc;
            }, {});
            let traceResultsJSON = resultsTableData.reduce(function (acc, cur) {
                let value = Math.round(cur.tracePercentComposition / 100 * 10000) / 10000;
                if (value > 0) {
                    acc[cur.substance] = value;
                }
                return acc;
            }, {});
            setAllScanData(prevAllScanData => {
                let newAllScanData = [...prevAllScanData];
                newAllScanData[scanIndex].results = resultsJSON;
                newAllScanData[scanIndex].trace_results = traceResultsJSON;
                return newAllScanData;
            });
        }
    }, [resultsTableData]);

    function CustomCell(props) {
        const shouldInteract = allSubstancesData?.[props.children?.[1]?.props?.children?.[0]]?.info?.overview?.length;
        if (shouldInteract) {
            return (
                <td {...props} style={{ cursor: 'pointer' }} />
            );
        }
        return <td {...props} />;
    }

    useEffect(() => {
        if (!showAdditionalFilters) {
            setSelectedSubstancesForResultFilter([]);
            setResultsFilterIsIncusive(true);
            setScanDetectedFilterMethod('AND');
        }
    }, [showAdditionalFilters]);

    useEffect(() => {
        resultsFilterIsIncusive === true ? setScanDetectedFilterMethod('AND') : setScanDetectedFilterMethod('OR');
    }, [resultsFilterIsIncusive]);

    const passesAdditionalFilters = record => {
        let pass = true;
        let numSubstancesInResults = Object.keys(record?.results || {}).length;
        if (selectedSubstancesForResultFilter.length && numSubstancesInResults > 0) {
            if (scanDetectedFilterMethod === 'AND') {
                pass = selectedSubstancesForResultFilter.every(substance => record.results.hasOwnProperty(substance)) ? true : false;
            } else {
                pass = selectedSubstancesForResultFilter.some(substance => record.results.hasOwnProperty(substance)) ? true : false;
            }
            pass = resultsFilterIsIncusive ? pass : !pass;
        } else if (selectedSubstancesForResultFilter.length && numSubstancesInResults === 0) {
            pass = false;
        }
        return pass;
    }

    const searchDatabaseForLabSampleID = async searchString => {
        let searchResults = (await searchMassSpecData(searchString)).map(e => ({ key: e.id, ...e }));
        setMassSpectrometryTableData(prev => [...prev, ...searchResults]);
        return searchResults;
    }

    const handleLabSampleIDSearch = async input => {
        const searchString = (input?.target?.value ?? input ?? '').toLowerCase();
        let matchingLabSampleIDs = massSpectrometryTableData.filter(s => s.id.substring(0, 8).includes(searchString)).map(s => ({
            value: s.id,
            label: `${s.id.substring(0, 8).toUpperCase()} (${s.status})`
        }));
        if (searchString.length && !matchingLabSampleIDs.length) {
            const searchResults = await searchDatabaseForLabSampleID(searchString);
            matchingLabSampleIDs = searchResults.map(s => ({
                value: s.id,
                label: `${s.id.substring(0, 8).toUpperCase()} (${s.status})`
            }));
        }
        setLabSampleIDMatches(matchingLabSampleIDs);
    }

    const fetchRecordLabSampleData = async id => {
        if (!id) {
            setRecordLabSampleData(null);
            return;
        }
        let sampleData = massSpectrometryTableData.find(s => s.id === id);
        if (!sampleData) {
            const searchResults = await searchDatabaseForLabSampleID(id.substring(0, 8));
            sampleData = searchResults.find(s => s.id === id);
        }
        setRecordLabSampleData(sampleData);
        return;
    }

    const autoSaveRecord = async (serial) => {
        if (username === 'admin') { return; }
        const record = allScanData.find(e => e.serial === serial);
        if (!record.client_code) {
            record.client_code = crypto.randomUUID();
        }
        try {
            await saveScanMetadata(record);
            await saveClientData(record);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <ImageModal />
            <Row justify="center" align="middle">
                <Col span={21} style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={`${showAdditionalFilters ? 'Hide' : 'Show'} additional filters`} placement="topLeft">
                        <Button
                            type="default"
                            icon={<FilterOutlined />}
                            className={classes.topButtons}
                            onClick={() => {
                                setShowAdditionalFilters(!showAdditionalFilters)
                            }}
                            disabled={scanning}
                        />
                    </Tooltip>
                    <RangePicker
                        style={{ flex: '1', margin: '0 3px' }}
                        className={classes.rangePicker}
                        onChange={rangePickerChangeHandler}
                        value={[dayjs(dateRange.from), dayjs(dateRange.to)]}
                        presets={[
                            { label: 'This Week', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
                            { label: 'Last Week', value: [dayjs().subtract(1, 'weeks').startOf('week'), dayjs().subtract(1, 'weeks').endOf('week')] },
                            { label: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                            { label: 'Last 4 Weeks', value: [dayjs().subtract(3, 'weeks').startOf('week'), dayjs().endOf('week')] },
                            { label: 'Last Month', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
                            { label: 'This Year', value: [dayjs().startOf('year'), dayjs().endOf('year')] }
                        ]}
                        allowClear={false}
                        disabled={scanning}
                        format={(value) => dayjs(value).format('MMM D, YYYY')}
                    />
                    <Tooltip title="Clear filters" placement="topRight">
                        <Button
                            type="default"
                            icon={<UndoOutlined />}
                            className={classes.topButtons}
                            onClick={() => {
                                setFilteredInfo({});
                                setSelectedSubstancesForResultFilter([]);
                                setResultsFilterIsIncusive(true);
                                setScanDetectedFilterMethod('AND');
                            }}
                            disabled={scanning}
                        />
                    </Tooltip>
                </Col>
            </Row>
            {showAdditionalFilters ?
                <Row justify="center" align="middle" style={{ margin: '6px 0' }}>
                    <Col span={21} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Space.Compact block style={{ flex: 1, margin: '0 3px' }}>
                            <Button
                                style={{
                                    cursor: 'default',
                                    pointerEvents: 'none',
                                }}
                            >
                                Scan
                            </Button>
                            <Select
                                style={{ flex: 'none', width: 'auto', textAlign: 'center' }}
                                suffixIcon={null}
                                popupMatchSelectWidth={false}
                                value={resultsFilterIsIncusive}
                                onChange={(e) => setResultsFilterIsIncusive(e)}
                                options={[
                                    {
                                        label: 'detected',
                                        value: true,
                                    },
                                    {
                                        label: 'did not detect',
                                        value: false,
                                    },
                                ]}
                            />
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ flex: '1' }}
                                suffixIcon={null}
                                placeholder="Add substances"
                                value={selectedSubstancesForResultFilter}
                                onChange={(e) => setSelectedSubstancesForResultFilter(e)}
                                options={detectedSubstances.map(s => ({ label: s[0], value: s[0] }))}
                            />
                        </Space.Compact>
                        {
                            selectedSubstancesForResultFilter.length >= 2 ?
                                <Radio.Group
                                    style={{ flexShrink: 0, margin: '0 3px' }}
                                    options={[
                                        { label: 'AND', value: 'AND' },
                                        { label: 'OR', value: 'OR' },
                                    ]}
                                    onChange={(e) => { setScanDetectedFilterMethod(e.target.value); }}
                                    value={scanDetectedFilterMethod}
                                    optionType="button" /> : null
                        }
                    </Col>
                </Row> : null
            }
            <Row justify="center" align="middle" style={{ marginTop: "6px" }}>
                <Col span={21} style={{ textAlign: "center" }} >
                    <Table
                        dataSource={allScanData.filter(passesAdditionalFilters)}
                        onChange={handleTableChange}
                        columns={clientUploadColumns}
                        className={classes.mapsTable}
                        expandable={{
                            columnWidth: 1,
                            expandRowByClick: true,
                            expandIconColumnIndex: -1,
                            expandIcon,
                            expandedRowKeys: expandedRows,
                            onExpand: (expanded, record) => {
                                if (expandedRows[0]) { autoSaveRecord(expandedRows[0]) };
                                setExpandedRows(expanded ? [record.key] : []);
                                fetchRecordLabSampleData(record?.lab_sample_id);
                            },
                            expandedRowRender: record => {
                                if (record.serial !== expandedRows[0]) {
                                    return null;
                                }
                                const resultsTableData = record.results ? Object.entries(record.results).map(([key, value]) => {
                                    return {
                                        key: key,
                                        substance: key,
                                        percentComposition: Math.round(value * 100 * 100) / 100,
                                        tracePercentComposition: record.trace_results?.[key] ? Math.round(record.trace_results[key] * 100 * 100) / 100 : null
                                    }
                                }) : [];
                                const resultTableColumns = [
                                    {
                                        title: 'Substance',
                                        dataIndex: 'substance',
                                        key: 'substance',
                                        width: '50%',
                                        render: (substance) => (<>{substance} {allSubstancesData?.[substance]?.info?.overview?.length ? <InfoCircleOutlined style={{ float: 'right' }} /> : null}</>),
                                        onCell: record => { return { onClick: _ => { if (allSubstancesData?.[record.substance]?.info?.overview?.length) { setInfoModalSubstance(record.substance); setIsSubstanceInfoModalOpen(true); } } } },
                                    },
                                    {
                                        title: 'Percent Composition',
                                        dataIndex: 'percentComposition',
                                        key: 'percentComposition',
                                        width: '50%',
                                        sorter: (a, b) => a.percentComposition - b.percentComposition,
                                        sortOrder: true,
                                        render: (percentComposition, results) => (
                                            <>
                                                <Progress strokeColor={'#91caff'} percent={percentComposition} success={{ percent: results.tracePercentComposition ? (percentComposition - results.tracePercentComposition) : percentComposition, strokeColor: '#1677ff' }} style={{ width: '95%' }} status="normal" />
                                            </>
                                        ),
                                    }
                                ];
                                return (
                                    <>
                                        {(record.serial === currentSerialNumber.current && scanning) ? <Badge status="processing" text="In Progress" style={{ position: 'absolute', right: '30px' }} /> : null}
                                        <Descriptions title="Scan Info" bordered={true} column={1} size="small" className={classes.expandedTable}>
                                            <Descriptions.Item label="Serial No." labelStyle={{ width: '30px' }}><div>{record.serial}</div></Descriptions.Item>
                                            <Descriptions.Item label="Date">{dayjs(record.created_at).format('MMM D, YYYY')}</Descriptions.Item>
                                            <Descriptions.Item label="Operator">
                                                <Select
                                                    showSearch
                                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    suffixIcon={null}
                                                    style={{ width: '100%' }}
                                                    className={(record.operator_id || record.operator) && !record.newOperator ? classes.cellHasData : null}
                                                    value={record.operator_id || record.operator}
                                                    // Uncomment when antd version >= 5.15.0
                                                    // labelRender={(label) => {
                                                    //     if (record.operator_id && operatorsMap?.[label.value]) {
                                                    //         const { first_name, last_name, color } = operatorsMap[label.value];
                                                    //         return (
                                                    //             <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }} >
                                                    //                 <p style={{ margin: '0px' }}>{first_name} {last_name}</p>
                                                    //                 <Avatar size="small" shape="square" style={{ backgroundColor: color, color: '#fff', display: 'flex', alignItems: 'center', fontSize: '10px' }}>
                                                    //                     {first_name[0]}{last_name[0]}
                                                    //                 </Avatar>
                                                    //             </div>
                                                    //         );
                                                    //     } else { return record.operator_id || record.operator; }
                                                    // }}
                                                    optionRender={(option) => (
                                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }} >
                                                            <p style={{ margin: '0px' }}>{option.data.first_name} {option.data.last_name}</p>
                                                            <Avatar size="small" shape="square" style={{ backgroundColor: option.data.color, color: '#fff', display: 'flex', alignItems: 'center', fontSize: '10px' }}>
                                                                {option.data.first_name[0]}{option.data.last_name[0]}
                                                            </Avatar>
                                                        </div>
                                                    )}
                                                    onChange={e => { record.operator_id = e; record.newOperator = true; handleRecordChange(record); }}
                                                    options={operators.map(operator => ({ ...operator, value: operator.operator_id, label: `${operator.first_name} ${operator.last_name}` }))}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Client Code">
                                                <AutoComplete
                                                    style={{ width: '100%' }}
                                                    className={record.client_code && !record.newClientCode ? classes.cellHasData : null}
                                                    options={clientCodes.map(e => ({ value: e.client_code })).filter(e => !isUUID(e.value))}
                                                    placeholder="Add client code"
                                                    onChange={(e) => {
                                                        if (record.client_code && !record.newClientCode) { participantProfileFormRef.current.storedClientCode = record.client_code; }
                                                        record.client_code = e;
                                                        record.newClientCode = true;
                                                        (throttle(_ => {
                                                            const matchingClient = clientCodes.find(client => client.client_code === record.client_code);
                                                            if (matchingClient) {
                                                                record.gender = matchingClient.gender;
                                                                record.age = matchingClient.age;
                                                                record.chronic_pain = matchingClient.chronic_pain;
                                                                record.indigenous = matchingClient.indigenous;
                                                                record.brpoc = matchingClient.brpoc;
                                                                record.lgbtq = matchingClient.lgbtq;
                                                                record.preferred_language = matchingClient.preferred_language;
                                                                participantProfileFormRef.current.setFieldsValue({
                                                                    gender: matchingClient.gender,
                                                                    age: matchingClient.age,
                                                                    chronic_pain: matchingClient.chronic_pain,
                                                                    indigenous: matchingClient.indigenous,
                                                                    brpoc: matchingClient.brpoc,
                                                                    lgbtq: matchingClient.lgbtq,
                                                                    preferred_language: matchingClient.preferred_language
                                                                });
                                                            } else {
                                                                record.gender = participantProfileFormRef.current.gender;
                                                                record.age = participantProfileFormRef.current.age;
                                                                record.chronic_pain = participantProfileFormRef.current.chronic_pain;
                                                                record.indigenous = participantProfileFormRef.current.indigenous;
                                                                record.brpoc = participantProfileFormRef.current.brpoc;
                                                                record.lgbtq = participantProfileFormRef.current.lgbtq;
                                                                record.preferred_language = participantProfileFormRef.current.preferred_language;
                                                                participantProfileFormRef.current.setFieldsValue({
                                                                    gender: participantProfileFormRef.current.gender,
                                                                    age: participantProfileFormRef.current.age,
                                                                    chronic_pain: participantProfileFormRef.current.chronic_pain,
                                                                    indigenous: participantProfileFormRef.current.indigenous,
                                                                    brpoc: participantProfileFormRef.current.brpoc,
                                                                    lgbtq: participantProfileFormRef.current.lgbtq,
                                                                    preferred_language: participantProfileFormRef.current.preferred_language
                                                                });
                                                            }
                                                            handleRecordChange(record);
                                                        }, 300))()
                                                    }}
                                                    allowClear={true}
                                                    onClear={(e) => {
                                                        handleRecordChange(record);
                                                        participantProfileFormRef.current.setFieldsValue({
                                                            gender: participantProfileFormRef.current.gender,
                                                            age: participantProfileFormRef.current.age,
                                                            chronic_pain: participantProfileFormRef.current.chronic_pain,
                                                            indigenous: participantProfileFormRef.current.indigenous,
                                                            brpoc: participantProfileFormRef.current.brpoc,
                                                            lgbtq: participantProfileFormRef.current.lgbtq,
                                                            preferred_language: participantProfileFormRef.current.preferred_language
                                                        });
                                                    }}
                                                    value={record.client_code ? record.client_code : ``}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Client Profile">
                                                <Form
                                                    ref={participantProfileFormRef}
                                                    initialValues={{
                                                        gender: record.gender ? record.gender : undefined,
                                                        age: record.age ? record.age : undefined,
                                                        chronic_pain: record.chronic_pain !== null ? record.chronic_pain : undefined,
                                                        indigenous: record.indigenous !== null ? record.indigenous : undefined,
                                                        brpoc: record.brpoc !== null ? record.brpoc : undefined,
                                                        lgbtq: record.lgbtq !== null ? record.lgbtq : undefined,
                                                        preferred_language: record.preferred_language ? record.preferred_language : 'en',
                                                    }}
                                                    labelCol={{
                                                        xs: { span: 24 },
                                                        sm: { span: 14 },
                                                        md: { span: 9 },
                                                        lg: { span: 8 },
                                                    }}
                                                    wrapperCol={{
                                                        xs: { span: 24 },
                                                        sm: { span: 10 },
                                                        md: { span: 15 },
                                                        lg: { span: 16 },
                                                    }}
                                                    onFieldsChange={() => {
                                                        if (!record.client_code) {
                                                            record.client_code = crypto.randomUUID(); record.newClientCode = true; handleRecordChange(record);
                                                        }
                                                    }}
                                                    onFinish={async values => {
                                                        if (!record.client_code) {
                                                            record.client_code = crypto.randomUUID(); record.newClientCode = true; handleRecordChange(record);
                                                        }
                                                        for (let key in values) {
                                                            if (values.hasOwnProperty(key) && values[key] === undefined && record.hasOwnProperty(key)) {
                                                                values[key] = record[key];
                                                            }
                                                        }
                                                        saveClientData({ client_code: record.client_code, username, ...values });
                                                        if (participantProfileFormRef?.current?.storedClientCode) {
                                                            let response = await deleteClient(username, participantProfileFormRef.current.storedClientCode);
                                                            if (response.clientCode) {
                                                                message.info(`Client deleted: ${response.clientCode}`, 6);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Form.Item label="Gender" name="gender" style={{ marginBottom: '8px' }}>
                                                        <Select
                                                            allowClear
                                                            suffixIcon={null}
                                                            style={{ width: '100%' }}
                                                            placeholder="Select gender"
                                                            onChange={e => { record.gender = e; handleRecordChange(record); participantProfileFormRef.current.gender = e; }}
                                                        >
                                                            <Option key={'Man'}>{'Man'}</Option>
                                                            <Option key={'Woman'}>{'Woman'}</Option>
                                                            <Option key={'Non-binary'}>{'Non-binary'}</Option>
                                                            <Option key={'Prefer not to say'}>{'Prefer not to say'}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Age" name="age" style={{ marginBottom: '8px' }}>
                                                        <InputNumber
                                                            style={{ width: '100%' }}
                                                            controls={false}
                                                            min={0}
                                                            max={100}
                                                            onChange={e => { record.age = e; handleRecordChange(record); participantProfileFormRef.current.age = e; }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label="Chronic Pain" name="chronic_pain" style={{ marginBottom: '8px' }}>
                                                        <Radio.Group options={[
                                                            { label: 'Yes', value: true },
                                                            { label: 'No', value: false },
                                                        ]} optionType="button"
                                                            onChange={(e) => { record.chronic_pain = e.target.value; handleRecordChange(record); participantProfileFormRef.current.chronic_pain = e.target.value; }} />
                                                    </Form.Item>
                                                    <Form.Item label="Indigenous" name="indigenous" style={{ marginBottom: '8px' }}>
                                                        <Radio.Group options={[
                                                            { label: 'Yes', value: true },
                                                            { label: 'No', value: false },
                                                        ]} optionType="button"
                                                            onChange={(e) => { record.indigenous = e.target.value; handleRecordChange(record); participantProfileFormRef.current.indigenous = e.target.value; }} />
                                                    </Form.Item>
                                                    <Form.Item label="Black, racialized or a POC" name="brpoc" style={{ marginBottom: '8px' }}>
                                                        <Radio.Group options={[
                                                            { label: 'Yes', value: true },
                                                            { label: 'No', value: false },
                                                        ]} optionType="button"
                                                            onChange={(e) => { record.brpoc = e.target.value; handleRecordChange(record); participantProfileFormRef.current.brpoc = e.target.value; }} />
                                                    </Form.Item>
                                                    <Form.Item label="2SLGBTQIA+" name="lgbtq" style={{ marginBottom: '8px' }}>
                                                        <Radio.Group options={[
                                                            { label: 'Yes', value: true },
                                                            { label: 'No', value: false },
                                                        ]} optionType="button"
                                                            onChange={(e) => { record.lgbtq = e.target.value; handleRecordChange(record); participantProfileFormRef.current.lgbtq = e.target.value; }} />
                                                    </Form.Item>
                                                    <Form.Item label="Preferred Language" name="preferred_language" style={{ marginBottom: '0px' }}>
                                                        <Select
                                                            showSearch
                                                            placeholder="Select language"
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={languages}
                                                            onChange={e => { record.preferred_language = e; handleRecordChange(record); participantProfileFormRef.current.preferred_language = e; }}
                                                        />
                                                    </Form.Item>
                                                </Form>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Testing for">
                                                <Radio.Group
                                                    options={[
                                                        { label: 'Self', value: 'self' },
                                                        { label: 'Third-Party', value: 'third-party' },
                                                        { label: 'Both', value: 'both' },
                                                    ]}
                                                    optionType="button"
                                                    value={record.testing_for}
                                                    onChange={(e) => { record.testing_for = e.target.value; handleRecordChange(record); }}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Expected Substances">
                                                <Select
                                                    mode="multiple"
                                                    allowClear
                                                    suffixIcon={null}
                                                    style={{ width: '100%' }}
                                                    className={(record.expected_substances || []).length > 0 && !record.newExpectedSubstances ? classes.cellHasData : null}
                                                    placeholder="Add expected substances"
                                                    value={record.expected_substances ? record.expected_substances : undefined}
                                                    onChange={e => { record.expected_substances = e; record.newExpectedSubstances = true; handleRecordChange(record); }}
                                                >
                                                    {expectedSubstances.map(e => <Option key={e}>{e}</Option>)}
                                                    <Option key={'Other'}>{'Other (Please specify in notes)'}</Option>
                                                </Select>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Description">
                                                <TextArea
                                                    className={record.substance_description && !record.newDescription ? classes.cellHasData : null}
                                                    placeholder="Colour, consistency, special attributes..."
                                                    autoSize
                                                    value={record.substance_description}
                                                    onChange={e => { record.substance_description = e.target.value; record.newDescription = true; handleRecordChange(record); }}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<span> Results<br /><Tooltip placement="right" title="View in window"><ExportOutlined onClick={() => { setImageInfoSerial(record.serial); setIsTestInfoModalOpen(true); }} /></Tooltip></span>}>
                                                <Table
                                                    style={{ width: '100%' }}
                                                    size="small"
                                                    dataSource={resultsTableData}
                                                    columns={resultTableColumns}
                                                    className={classes.resultsTable}
                                                    pagination={{ position: ['bottomCenter'], pageSize: 5 }}
                                                    components={{
                                                        body: {
                                                            cell: CustomCell
                                                        }
                                                    }}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Scan Impact">
                                                <Select
                                                    allowClear
                                                    suffixIcon={null}
                                                    style={{ width: '100%' }}
                                                    className={record.impact && !record.newImpact ? classes.cellHasData : null}
                                                    disabled={!record.results}
                                                    placeholder="Select impact"
                                                    value={record.impact}
                                                    onChange={e => { record.impact = e; record.newImpact = true; handleRecordChange(record); }}
                                                >
                                                    <Option key={'Unknown'}>Unknown</Option>
                                                    <Option key={'No Change'}>No Change</Option>
                                                    <Option key={'Lowered Dose'}>Lowered Dose</Option>
                                                    <Option key={'Increased Dose'}>Increased Dose</Option>
                                                    <Option key={'Did Not Consume'}>Did Not Consume</Option>
                                                </Select>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Strength">
                                                <Select
                                                    allowClear
                                                    suffixIcon={null}
                                                    style={{ width: '100%' }}
                                                    className={record.substance_strength && !record.newSubstanceStrength ? classes.cellHasData : null}
                                                    placeholder="Select strength"
                                                    value={record.substance_strength}
                                                    disabled={(record.impact === 'Did Not Consume' ? true : false)}
                                                    onChange={e => { record.substance_strength = e; record.newSubstanceStrength = true; handleRecordChange(record); setRiskAwarenessShouldUpdate(true); }}
                                                >
                                                    <Option key={'Weak'}>{'Weak'}</Option>
                                                    <Option key={'Normal'}>{'Normal'}</Option>
                                                    <Option key={'Strong'}>{'Strong'}</Option>
                                                    <Option key={'Very Strong'}><span><WarningTwoTone twoToneColor="#faad14" /> {'Very Strong'}</span></Option>
                                                    <Option key={'Overdose'}><span><WarningTwoTone twoToneColor="#f5222d" /> {'Overdose'}</span></Option>
                                                </Select>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Consumed">
                                                <Radio.Group
                                                    options={[
                                                        { label: 'Onsite', value: 'onsite' },
                                                        { label: 'Offsite', value: 'offsite' },
                                                        { label: 'N/A', value: 'na' },
                                                    ]}
                                                    optionType="button"
                                                    value={record.consumed}
                                                    onChange={(e) => { record.consumed = e.target.value; handleRecordChange(record); }}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Notes">
                                                <TextArea
                                                    className={record.notes && !record.newNotes ? classes.cellHasData : null}
                                                    placeholder="Side effects, amount consumed, onset, administered first aid..."
                                                    autoSize
                                                    value={record.notes}
                                                    onChange={e => { record.notes = e.target.value; record.newNotes = true; handleRecordChange(record); }}
                                                />
                                            </Descriptions.Item>
                                            {isAdmin &&
                                                <Descriptions.Item label="Laboratory Sample ID">
                                                    <Select
                                                        allowClear
                                                        showSearch
                                                        suffixIcon={null}
                                                        style={{ width: '100%' }}
                                                        className={record.lab_sample_id && !record.newlabSampleID ? classes.cellHasData : null}
                                                        placeholder="Search for sample"
                                                        value={recordLabSampleData ? `${recordLabSampleData.id.substring(0, 8).toUpperCase()} (${recordLabSampleData.status})` : null}
                                                        onChange={e => { record.lab_sample_id = e; record.newlabSampleID = true; handleRecordChange(record); }}
                                                        onFocus={handleLabSampleIDSearch}
                                                        onSearch={handleLabSampleIDSearch}
                                                        options={labSampleIDMatches || []}
                                                    />
                                                    {recordLabSampleData ?
                                                        <Descriptions style={{ marginTop: '8px' }} bordered={true} column={1} size="small" labelStyle={{ width: '120px' }}>
                                                            <Descriptions.Item label="Request Date">{dayjs(recordLabSampleData.created_at).format('MMM D, YYYY')}</Descriptions.Item>
                                                            <Descriptions.Item label="Expected">{recordLabSampleData.expected_substances?.map(substance => (
                                                                <Tag key={substance}>{substance}</Tag>
                                                            ))}</Descriptions.Item>
                                                            <Descriptions.Item label="Description">{recordLabSampleData.description}</Descriptions.Item>
                                                            <Descriptions.Item label="Client Code">{recordLabSampleData.client_code}</Descriptions.Item>
                                                            <Descriptions.Item label="Lab Notes">{recordLabSampleData.laboratory_comments}</Descriptions.Item>
                                                            <Descriptions.Item label="Username">{recordLabSampleData.username}</Descriptions.Item>
                                                        </Descriptions> : null}
                                                </Descriptions.Item>
                                            }
                                            <Descriptions.Item label="Action">
                                                <Button
                                                    type="primary" ghost
                                                    disabled={disabledOfflineActions || scanning || !record.device_id || !record.results || Object.keys(record.results).length === 0}
                                                    onClick={() => {
                                                        mapViewButtonHandler({ map_id: record.map_id, serial: record.serial, device_id: record.device_id });
                                                        message.loading({ content: 'Loading...', key, duration: 0 });
                                                        setImageInfoSerial(record.serial);
                                                    }}
                                                >
                                                    View
                                                </Button>
                                                <Button
                                                    type="primary" ghost
                                                    style={{ marginLeft: '6px' }}
                                                    disabled={record.serial > 0 && disabledOfflineActions}
                                                    onClick={() => { updateRecordHandler(record); participantProfileFormRef.current.submit(); }}
                                                >
                                                    Save Info
                                                </Button>
                                                {record.image_filename && username === 'admin' ?
                                                    <Button
                                                        type="primary" ghost
                                                        style={{ marginLeft: '6px' }}
                                                        disabled={disabledOfflineActions}
                                                        onClick={() => {
                                                            setImageFilename(record.image_filename);
                                                            setIsImageModalOpen(true);
                                                        }}
                                                    >
                                                        Show Image
                                                    </Button> : null
                                                }
                                                {username === 'admin' &&
                                                    <Button
                                                        type="primary" danger ghost
                                                        style={{ float: 'right' }}
                                                        disabled={disabledOfflineActions || scanning} // TODO: add a check for serial > 0 for offline when table update logic is done
                                                        onClick={() => {
                                                            confirmRecordDelete(record);
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                }
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </>
                                );
                            },
                            rowExpandable: record => true,
                        }
                        }
                        pagination={{
                            position: ['bottomCenter'],
                            pageSize: 10,
                            showSizeChanger: false,
                        }}
                        scroll={{ x: '1235px' }}
                        sticky
                        loading={dataLoading}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ClientUploads;