import { Input, Modal, Space } from 'antd';
import React, { useState } from 'react';
import { writeToDevice, readEEPROM } from '../utils/bluetoothLogic';
import { useAtom } from 'jotai';
import { deviceInfoState } from '../stateManagement/commonState';
import { arrayBufferToBufferCycle } from '../utils/helpers';
import { parseEEPROM } from '../utils/parseEEPROM';
import { updateDeviceNetworkStatusInDB } from '../utils/dbHelpers';

const WifiConfigModal = ({ isModalOpen, setWifiConfigModalOpen, characteristic }) => {
  const [connectionName, setConnectionName] = useState('');
  const [connectionUsername, setConnectionUsername] = useState('');
  const [connectionPassword, setConnectionPassword] = useState('');
  const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoState)

  const okHandler = async () => {
    await writeToDevice(characteristic, [
      // ['connectToWifi', [connectionName, connectionPassword, connectionUsername]],
      ['connectToWifi', [connectionName, connectionPassword]],
      ['executeCommands']
    ]);
    setWifiConfigModalOpen(false);
    setDeviceInfo({ ...deviceInfo, device_network_status: -1 });
    setTimeout(async () => {
      let eepromData = await readEEPROM(characteristic);
      let eepromDataBuffer = arrayBufferToBufferCycle(eepromData.buffer);
      let currentDeviceInfo = { ...parseEEPROM(eepromDataBuffer) }
      await updateDeviceNetworkStatusInDB(currentDeviceInfo.device_id, currentDeviceInfo.device_network_status);
      setDeviceInfo(currentDeviceInfo);
    }, 15000);
  }

  const onCancel = () => {
    setWifiConfigModalOpen(false);
  }

  return (
    <>
      <Modal
        title='Add your WiFi network credentials'
        open={isModalOpen}
        onOk={okHandler}
        onCancel={onCancel}
      >
        {/* <Space direction='vertical'> */}
          <Input
            placeholder='Network Name (SSID)'
            onChange={(e) => { setConnectionName(e.target.value) }}
          />
          {/* <Input
            placeholder='Username (leave blank if none)'
            onChange={(e) => { setConnectionUsername(e.target.value) }}
          /> */}
          <Input.Password
            placeholder='Password (leave blank if none)'
            onChange={(e) => { setConnectionPassword(e.target.value) }}
          />
        {/* </Space> */}
      </Modal>
    </>
  );
};

export default WifiConfigModal;