import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useAtom } from 'jotai';
import ReactEcharts from 'echarts-for-react';
import { Tooltip, Button, Popover, Space, Cascader, Row, Col } from 'antd';
import { RedoOutlined, DownloadOutlined } from '@ant-design/icons';
import { cloneDeep, indexOf } from 'lodash';
import { getWavenumbers } from '../utils/wavenumbers';
import { deviceInfoState, mlOutputFilterIndicesState } from '../stateManagement/commonState';
import { fetchReferenceSubstanceNames, fetchReferenceSpectrum } from '../utils/dbHelpers.js'
import { substanceArray as mlSubstanceArray } from '../utils/substancesAndColors';

const SpectraLineChart = ({ linechartSpectrum, updateWavenumberFilter }) => {
    const [deviceInfo] = useAtom(deviceInfoState);
    let wavenumbers = deviceInfo ? getWavenumbers(500, deviceInfo.wavelength_calibration, deviceInfo.excitation_wavelength) : [];
    let standardWavenumbers = [...Array(2324 - 225 + 1)].map((_, i) => 225 + i);
    const spectrumLineChartRef = useRef(null);
    const wavenumberFilterIndexRef = useRef(null);
    const [referenceSubstanceCascaderOptions, setReferenceSubstanceCascaderOptions] = useState([]);
    const [currentReferenceSubstances, setCurrentReferenceSubstances] = useState([]);
    const [referenceSpectrumSeries, setReferenceSpectrumSeries] = useState([]);
    const [prevReferenceSeriesCount, setPrevReferenceSeriesCount] = useState(0);
    const [, setMLOutputFilterIndices] = useAtom(mlOutputFilterIndicesState);
    const [selectedReferenceSubstances, setSelectedReferenceSubstances] = useState([]);

    const [lineChartOption, setLineChartOption] = useState({
        legend: {
            data: ['Raw', 'Bad Pixel Corrected', 'Smoothed', 'Baseline Corrected', 'Intensity Corrected', 'Interpolated', 'Processed', 'Normalized'],
            bottom: 1,
            selected: {
                'Raw': false,
                'Bad Pixel Corrected': false,
                'Smoothed': false,
                'Baseline Corrected': false,
                'Intensity Corrected': false,
                'Interpolated': false,
            },
            top: 310
        },
        tooltip: {
            trigger: 'axis',
        },
        dataZoom: [
            {
                type: 'inside'
            }
        ],
        xAxis: {
            type: 'value',
            data: standardWavenumbers,
            min: standardWavenumbers[0],
            max: standardWavenumbers[standardWavenumbers.length - 1],
        },
        yAxis: {
            type: 'value',
            scale: true,
        },
        grid: {
            top: '20px',
            right: '15px',
            bottom: '100px',
            left: '15px',
            containLabel: true,
        },
        series: [
            {
                name: 'Raw',
                data: new Array(500).fill(null).map((e, i) => [wavenumbers[i], 0]),
                type: 'line',
                showSymbol: false,
            },
            {
                name: 'Bad Pixel Corrected',
                data: new Array(500).fill(null).map((e, i) => [wavenumbers[i], 0]),
                type: 'line',
                showSymbol: false,
            },
            {
                name: 'Smoothed',
                data: new Array(500).fill(null).map((e, i) => [wavenumbers[i], 0]),
                type: 'line',
                showSymbol: false,
            },
            {
                name: 'Baseline Corrected',
                data: new Array(500).fill(null).map((e, i) => [wavenumbers[i], 0]),
                type: 'line',
                showSymbol: false,
            },
            {
                name: 'Intensity Corrected',
                data: new Array(500).fill(null).map((e, i) => [wavenumbers[i], 0]),
                type: 'line',
                showSymbol: false,
            },
            {
                name: 'Interpolated',
                data: new Array(2100).fill(null).map((e, i) => [standardWavenumbers[i], 0]),
                type: 'line',
                showSymbol: false,
            },
            {
                name: 'Processed',
                data: new Array(2100).fill(null).map((e, i) => [standardWavenumbers[i], 0]),
                type: 'line',
                showSymbol: false,
            },
            {
                name: 'Normalized',
                data: new Array(2100).fill(null).map((e, i) => [standardWavenumbers[i], 0]),
                type: 'line',
                showSymbol: false,
            },
            {
                silent: true,
                type: 'line',
                markLine: {
                    silent: true,
                    lineStyle: {
                        color: '#333'
                    },
                    symbol: ['none', 'none'],
                    data: []
                }
            }
        ],
    });

    useEffect(() => {
        getReferenceSubstanceNames();
    }, []);

    useEffect(() => {
        let newMLOutputFilterIndices = currentReferenceSubstances.reduce((MLIndices, curr) => {
            let substanceIndex = mlSubstanceArray.indexOf(curr.reference_substance);
            if (substanceIndex !== -1) { MLIndices.push(substanceIndex) }
            return MLIndices;
        }, []);
        setMLOutputFilterIndices(newMLOutputFilterIndices)
    }, [currentReferenceSubstances])

    useEffect(() => {
        wavenumbers = deviceInfo ? getWavenumbers(deviceInfo.active_pixels, deviceInfo.wavelength_calibration, deviceInfo.excitation_wavelength) : [];
        setLineChartOption(prevOption => {
            let newOption = cloneDeep(prevOption);
            newOption.series[0].data = linechartSpectrum.rawSpectrum.map((e, i) => [wavenumbers[i], e]);
            newOption.series[1].data = linechartSpectrum.badPixelCorrectedSpectrum?.map((e, i) => [wavenumbers[i], e]);
            newOption.series[2].data = linechartSpectrum.smoothedSpectrum?.map((e, i) => [wavenumbers[i], e]);
            newOption.series[3].data = linechartSpectrum.baselineCorrectedSpectrum?.map((e, i) => [wavenumbers[i], e]);
            newOption.series[4].data = linechartSpectrum.intensityCorrectedSpectrum?.map((e, i) => [wavenumbers[i], e]);
            newOption.series[5].data = linechartSpectrum.interpolatedSpectrum?.map((e, i) => [standardWavenumbers[i], e]);
            newOption.series[6].data = linechartSpectrum.processedSpectrum.map((e, i) => [standardWavenumbers[i], e]);
            newOption.series[7].data = linechartSpectrum.normalizedSpectrum?.map((e, i) => [standardWavenumbers[i], e]);
            return newOption
        });
        let newCurrentReferenceSubstances = cloneDeep(currentReferenceSubstances);
        setCurrentReferenceSubstances(newCurrentReferenceSubstances.map(e => ({ reference_substance: e.reference_substance, processed_spectrum: [], integration_time: null })));
    }, [deviceInfo, linechartSpectrum]);

    const getReferenceSubstanceNames = async () => {
        let referenceSubstanceNames = await fetchReferenceSubstanceNames();
        setReferenceSubstanceCascaderOptions(referenceSubstanceNames.map(e => ({ value: e.substance_description, label: e.substance_description })));
    }

    const updateWavenumberMarkline = wavenumber => {
        setLineChartOption(prevOption => {
            let newOption = cloneDeep(prevOption);
            newOption.series[8].markLine.data = [];
            if (wavenumber) { newOption.series[8].markLine.data.push({ xAxis: wavenumber }) };
            return newOption
        });
    }

    const downloadSpectrum = type => {
        let rows = [];
        if (type === 'interpolatedSpectrum' || type === 'normalizedSpectrum' || type === 'processedSpectrum') {
            rows = standardWavenumbers.map((e, i) => [e, linechartSpectrum[type][i]]);
        } else {
            rows = wavenumbers.map((e, i) => [e, linechartSpectrum[type][i]]);
        }
        const csvContent = rows
            .map((e) => e.join(","))
            .join("\n");
        const blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `Scatr_${linechartSpectrum.serial === null ? '' : `serial_${linechartSpectrum.serial}_`}index_${linechartSpectrum.index}_${type.replace(/[A-Z]/g, (match) => '_' + match.toLowerCase())}.csv`);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
    }

    useEffect(() => {
        setPrevReferenceSeriesCount(lineChartOption.series.length);
        if (spectrumLineChartRef && spectrumLineChartRef.current) {
            const notMerge = prevReferenceSeriesCount > lineChartOption.series.length;
            spectrumLineChartRef.current.getEchartsInstance().setOption(lineChartOption, notMerge);
        }
    }, [lineChartOption]);

    useEffect(() => {
        if (spectrumLineChartRef && spectrumLineChartRef.current) {
            let chart = spectrumLineChartRef.current.getEchartsInstance();
            chart.setOption(lineChartOption, false);
            chart.getZr().on('click', function (event) {
                let pointInPixel = [event.offsetX, event.offsetY];
                let pointInGrid = chart.convertFromPixel('grid', pointInPixel);
                if (pointInGrid[1] < 0) { return }
                let seriesDataX = chart.getOption().series[6].data.map(x => x[0]);
                let wavenumber = seriesDataX.reduce(function (prev, curr) {
                    return (Math.abs(curr - pointInGrid[0]) < Math.abs(prev - pointInGrid[0]) ? curr : prev);
                });
                updateWavenumberMarkline(wavenumber);
                wavenumberFilterIndexRef.current = indexOf(standardWavenumbers, wavenumber);
                updateWavenumberFilter(indexOf(standardWavenumbers, wavenumber));
            });
        }
    }, []);

    const handleSelectedReferenceChange = async (selectedReferenceSubstances) => {
        const newCurrentReferenceSubstances = selectedReferenceSubstances.map(e => ({
            reference_substance: e[0],
            processed_spectrum: currentReferenceSubstances.find(f => f.reference_substance === e[0])?.processed_spectrum || [],
            integration_time: currentReferenceSubstances.find(f => f.reference_substance === e[0])?.integration_time || null,
        }));
        for (let i = 0; i < newCurrentReferenceSubstances.length; i++) {
            if (newCurrentReferenceSubstances[i].processed_spectrum.length === 0) {
                let referenceSpectrumData = await fetchReferenceSpectrum(newCurrentReferenceSubstances[i].reference_substance, linechartSpectrum.integrationTime || 2000);
                newCurrentReferenceSubstances[i] = { ...newCurrentReferenceSubstances[i], ...referenceSpectrumData };
            }
        }
        setCurrentReferenceSubstances(newCurrentReferenceSubstances);
        setReferenceSpectrumSeries(newCurrentReferenceSubstances.map((e, i) => ({
            name: `${e.reference_substance} (${e.integration_time}ms)`,
            data: e.processed_spectrum.map((e, i) => [standardWavenumbers[i], e]),
            type: 'line',
            showSymbol: false,
        })));
    }

    useEffect(() => {
        handleSelectedReferenceChange(selectedReferenceSubstances);
    }, [selectedReferenceSubstances]);

    useEffect(() => {
        setLineChartOption(prevOption => ({
            ...prevOption,
            legend: {
                ...prevOption.legend,
                data: [
                    ...prevOption.legend.data.slice(0, 9),
                    ...referenceSpectrumSeries.map(e => e.name)
                ]
            },
            series: [
                ...prevOption.series.slice(0, 9),
                ...referenceSpectrumSeries
            ]
        }));
    }, [referenceSpectrumSeries]);


    const spectrumLineChart = useMemo(() =>
        <ReactEcharts
            option={lineChartOption}
            ref={spectrumLineChartRef}
            style={{ width: "100%", height: '100%' }}
        />,
        []
    );

    const filter = (inputValue, path) => path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

    return (
        <>
            <Row style={{ margin: '15px 15px 0px 15px' }}>
                <Col>
                    <Popover placement="topRight" title={`Download Spectrum`} content={(
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Button type="default" block onClick={() => downloadSpectrum('rawSpectrum')}>
                                Raw
                            </Button>
                            <Button type="default" block onClick={() => downloadSpectrum('badPixelCorrectedSpectrum')} disabled={linechartSpectrum.badPixelCorrectedSpectrum ? false : true}>
                                Bad Pixel Corrected
                            </Button>
                            <Button type="default" block onClick={() => downloadSpectrum('smoothedSpectrum')} disabled={linechartSpectrum.smoothedSpectrum ? false : true}>
                                Smoothed
                            </Button>
                            <Button type="default" block onClick={() => downloadSpectrum('baselineCorrectedSpectrum')} disabled={linechartSpectrum.baselineCorrectedSpectrum ? false : true}>
                                Baseline Corrected
                            </Button>
                            <Button type="default" block onClick={() => downloadSpectrum('intensityCorrectedSpectrum')} disabled={linechartSpectrum.intensityCorrectedSpectrum ? false : true}>
                                Intensity Corrected
                            </Button>
                            <Button type="default" block onClick={() => downloadSpectrum('interpolatedSpectrum')} disabled={linechartSpectrum.interpolatedSpectrum ? false : true}>
                                Interpolated
                            </Button>
                            <Button type="default" block onClick={() => downloadSpectrum('processedSpectrum')}>
                                Processed
                            </Button>
                            <Button type="default" block onClick={() => downloadSpectrum('normalizedSpectrum')} disabled={linechartSpectrum.normalizedSpectrum ? false : true}>
                                Normalized
                            </Button>
                        </Space>
                    )} trigger="click">
                        <Button icon={<DownloadOutlined />} disabled={linechartSpectrum.index === null ? true : false}></Button>
                    </Popover>
                </Col>
                <Col flex="auto">
                    <Cascader
                        multiple
                        maxTagCount='responsive'
                        style={{ width: '100%', padding: '0 3px 0 3px' }}
                        options={referenceSubstanceCascaderOptions}
                        onChange={e => setSelectedReferenceSubstances(e)}
                        placeholder="Add reference spectra"
                        showSearch={{ filter }}
                    />
                </Col>
                <Col>
                    <Tooltip placement="topRight" title={`Clear Filter`}>
                        <Button icon={<RedoOutlined />} onClick={() => { wavenumberFilterIndexRef.current = null; updateWavenumberFilter(null); updateWavenumberMarkline(null); }} disabled={wavenumberFilterIndexRef.current === null}></Button>
                    </Tooltip>
                </Col>
            </Row>
            <div style={{ height: '400px' }}>
                {spectrumLineChart}
            </div>
        </>
    );
}

export default SpectraLineChart